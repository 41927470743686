import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlayUrlContext from '~/context/play-url';
import InputContacts from '~/components/input-contact';
import LineHeader from '~/components/line-header';
import { SiFacebook, SiTwitter } from 'react-icons/si';
import { ImInstagram } from 'react-icons/im';
import { Link, graphql, useStaticQuery } from 'gatsby';

const ContentFooterWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #000;
  position: relative;
  font-family: 'Barboothatmatts';
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media screen and (min-width: 1024px) {
    padding-top: 15px;
    font-size: 3.5rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    padding-left: 10rem;
    padding-right: 10rem;
  }

  @media screen and (max-width: 450px) {
    padding-top: 8px;
    font-size: 1rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
  }
`;

const ContentsWrapper = styled.div`
  margin: 0 auto;

`;

const Header2 = styled.div`
  font-size: 1.65rem;

  @media screen and (min-width: 1024px) {
    font-size: 1.875rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.85rem;
  }
`;

const Header = styled.div`
  font-size: 2rem;

  @media screen and (min-width: 1024px) {
    padding-top: 15px;
    font-size: 2.5rem;
  }

  @media screen and (max-width: 450px) {
    padding-top: 8px;
    font-size: 1rem;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  padding-top: 15px;
  color: #fff;
  font-family: 'ZenAntique';
  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    padding-top: 15px;
    font-size: 1rem;
  }

  @media screen and (max-width: 450px) {
    padding-top: 8px;
    font-size: 0.75rem;
  }
`;

const Icon = styled.div`
  width: 30%;

  @media screen and (min-width: 1024px) {
    width: 35%;
  }
  @media screen and (max-width: 450px) {
    width: 35%;
  }
`;

const ContentFooter = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query {
      socials: allMarkdownRemark(filter: { collection: { eq: "social" } }) {
        edges {
          node {
            file
            frontmatter {
              address {
                display_name
                link
              }
            }
          }
        }
      }
      books: allMarkdownRemark(filter: { collection: { eq: "common" } }) {
        edges {
          node {
            file
            frontmatter {
              book_or_events_content
            }
          }
        }
      }
    }
  `);

  const socials = data.socials.edges.map((v) => v.node.frontmatter)[0];
  const books = data.books.edges.map((v) => v.node.frontmatter)[0];

  return (
    <PlayUrlContext.Consumer>
      {() => (
        <ContentFooterWrapper>
          <Header className='text-white text-center underline underline-offset-8'>
            BAR & RESTAURANTS
          </Header>
          <ContentsWrapper className='pt-12 md:pt-5 md:w-10/12'>
            <div className='grid grid-cols-3 gap-20 md:grid-cols-1 md:gap-5'>
              <div className='...'>
                <Header2>COSCA</Header2>
                <Content>
                  {books.book_or_events_content}
                </Content>
                <Icon className='grid grid-cols-3 gap-8 pt-5 md:pt-2 md:gap-2'>
                  <SiFacebook className='md:w-5' size={30} />
                  <ImInstagram className='md:w-5'size={30} />
                  <SiTwitter className='md:w-5' size={30} />
                </Icon>
              </div>
              <div className='...'>
                <Header2>LOCATION</Header2>
                <Content>
                  {socials.address.display_name}
                </Content>
              </div>
              <div className='...'>
                <Header2>CONTACTS</Header2>
                <Content>{/* <InputContacts></InputContacts> */}</Content>
              </div>
            </div>
          </ContentsWrapper>
        </ContentFooterWrapper>
      )}
    </PlayUrlContext.Consumer>
  );
};

ContentFooter.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
};

export default ContentFooter;
