import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlayUrlContext from '~/context/play-url';
import { Link, graphql, useStaticQuery } from 'gatsby';

const ContentGelleryWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #000;
  position: relative;
`;

const ImageWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const ContentGellery = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query {
      gallerys: allMarkdownRemark(filter: { collection: { eq: "gallery" } }) {
        edges {
          node {
            file
            frontmatter {
              desktop {
                image_1 {
                  image
                  alt_image
                }
                image_2 {
                  image
                  alt_image
                }
                image_3 {
                  image
                  alt_image
                }
                image_4 {
                  image
                  alt_image
                }
                image_5 {
                  image
                  alt_image
                }
                image_6 {
                  image
                  alt_image
                }
                image_7 {
                  image
                  alt_image
                }
                image_8 {
                  image
                  alt_image
                }
                image_9 {
                  image
                  alt_image
                }
              }
              mobile {
                image_1 {
                  image
                  alt_image
                }
                image_2 {
                  image
                  alt_image
                }
                image_3 {
                  image
                  alt_image
                }
                image_4 {
                  image
                  alt_image
                }
                image_5 {
                  image
                  alt_image
                }
              }
            }
          }
        }
      }
    }
  `);

  const gallerys = data.gallerys.edges.map((v) => v.node.frontmatter)[0];

  return (
    <PlayUrlContext.Consumer>
      {() => (
        <ContentGelleryWrapper className='py-16 md:py-6'>
          <ImageWrapper className='lg:hidden' style={{ margin: '0 auto' }}>
            <div className='grid grid-flow-row-dense grid-cols-2 grid-rows-8 gap-1'>
              <div className='row-span-2'>
                <img
                  src={gallerys.mobile.image_1.image}
                  alt={gallerys.mobile.image_1.alt_image}
                  className='h-full'
                />
              </div>
              <div className='row-span-4'>
                <img
                  src={gallerys.mobile.image_2.image}
                  alt={gallerys.mobile.image_2.alt_image}
                  className='h-full'
                />
              </div>
              <div className='row-span-3'>
                <img
                  src={gallerys.mobile.image_3.image}
                  alt={gallerys.mobile.image_3.alt_image}
                  className='h-full'
                />
              </div>
              <div className='row-span-4'>
                <img
                  src={gallerys.mobile.image_4.image}
                  alt={gallerys.mobile.image_4.alt_image}
                  className='h-full'
                />
              </div>
              <div className='row-span-3'>
                <img
                  src={gallerys.mobile.image_5.image}
                  alt={gallerys.mobile.image_5.alt_image}
                  className='h-full'
                />
              </div>
            </div>
          </ImageWrapper>
          <ImageWrapper className='md:hidden' style={{ margin: '0 auto' }}>
            <div className='grid grid-flow-row-dense grid-cols-10 grid-rows-8 gap-2'>
              <div className='col-span-3 row-span-2'>
                <img
                  src={gallerys.desktop.image_1.image}
                  alt={gallerys.desktop.image_1.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-3 row-span-5'>
                <img
                  src={gallerys.desktop.image_2.image}
                  alt={gallerys.desktop.image_2.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-4 row-span-3'>
                <img
                  src={gallerys.desktop.image_3.image}
                  alt={gallerys.desktop.image_3.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-3 row-span-3'>
                <img
                  src={gallerys.desktop.image_4.image}
                  alt={gallerys.desktop.image_4.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-2 row-span-2'>
                <img
                  src={gallerys.desktop.image_5.image}
                  alt={gallerys.desktop.image_5.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-2 row-span-2'>
                <img
                  src={gallerys.desktop.image_6.image}
                  alt={gallerys.desktop.image_6.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-3 row-span-3'>
                <img
                  src={gallerys.desktop.image_7.image}
                  alt={gallerys.desktop.image_7.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-3 row-span-3'>
                <img
                  src={gallerys.desktop.image_8.image}
                  alt={gallerys.desktop.image_8.alt_image}
                  className='h-full'
                />
              </div>
              <div className='col-span-4 row-span-3'>
                <img
                  src={gallerys.desktop.image_9.image}
                  alt={gallerys.desktop.image_9.alt_image}
                  className='h-full'
                />
              </div>
            </div>
          </ImageWrapper>
        </ContentGelleryWrapper>
      )}
    </PlayUrlContext.Consumer>
  );
};

ContentGellery.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
};

export default ContentGellery;
