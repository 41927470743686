import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlayUrlContext from '~/context/play-url';
import LineHeader from '~/components/line-header';
import ContentLayout from '~/components/content-layout';
import { Link, graphql, useStaticQuery } from 'gatsby';

const ContentUpdateWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
`;

const SentensesWrapper = styled.div`
  font-family: 'ZenAntique';
`;

const SmallSentensesWrapper = styled.small`
  color: #d5b471;
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
`;

const ContentUpdate = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { collection: { eq: "updated" } }) {
        edges {
          node {
            file
            frontmatter {
              updated_text
              updated_small_text
              image_1
              image_2
              image_3
              image_4
              image_5
              alt_image_1
              alt_image_2
              alt_image_3
              alt_image_4
              alt_image_5
            }
          }
        }
      }
    }
  `);
  
  let content = data.allMarkdownRemark.edges.find((item) =>
    item.node.file.includes('updated')
  ).node.frontmatter;
  content = Object.fromEntries(
    Object.entries(content).filter(([_, v]) => v != null)
  );
  let imageContent = data.allMarkdownRemark.edges.find((item) =>
    item.node.file.includes('updated_image')
  ).node.frontmatter;
  imageContent = Object.fromEntries(
    Object.entries(imageContent).filter(([_, v]) => v != null)
  );

  const pathImages = Object.fromEntries(
    Object.entries(imageContent).filter(([key, _]) => !key.includes('alt'))
  );
  const rightSectionImages = Object.keys(pathImages).slice(
    1,
    Object.keys(imageContent).length
  );
  return (
    <PlayUrlContext.Consumer>
      {() => (
        <ContentLayout>
          <ContentUpdateWrapper className='py-12 md:py-8'>
            <LineHeader content={'Updated'} />
            <section className='flex justify-center mb-5'>
              <SentensesWrapper className='w-1/2 md:w-10/12 text-lg md:text-xs'>
                <div className='text-center'>
                  <figure>{content.updated_text}</figure>
                  <SmallSentensesWrapper>
                    {content.updated_small_text}
                  </SmallSentensesWrapper>
                </div>
              </SentensesWrapper>
            </section>
            <ImageWrapper>
              <div className='grid grid-cols-2 md:grid-cols-1 gap-8 md:gap-4'>
                <div className='flex items-center'>
                  <img
                    src={`/${imageContent.image_1}`}
                    alt={imageContent.alt_image_1}
                  />
                </div>
                <div className='grid grid-cols-2 gap-8 md:gap-4'>
                  {rightSectionImages.map((key, idx) => (
                    <div className='relative flex items-center' key={idx}>
                      <img
                        src={`/${imageContent[key]}`}
                        alt={imageContent[`alt_image_${idx + 2}`]}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </ImageWrapper>
          </ContentUpdateWrapper>
        </ContentLayout>
      )}
    </PlayUrlContext.Consumer>
  );
};

ContentUpdate.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
};

export default ContentUpdate;
