import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlayUrlContext from '~/context/play-url';
import { Link, graphql, useStaticQuery } from 'gatsby';

import { SiFacebook } from 'react-icons/si';
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { ImInstagram } from 'react-icons/im';

const ContentContactWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #000;
  position: relative;
  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
`;

const ArticleWrapper = styled.div`
  height: auto;
  color: #fff;
`;

const ContentArticleWrapper = styled.div`
  font-family: ZenAntique;
  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

const TextIcon = styled.div`
  display: flex;
  justifycontent: start;
  margin-bottom: 10px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 450px) {
    margin-bottom: 0px;
  }
`;

const Text = styled.div`
  margin-left: 10px;
`;

const Header = styled.div`
  font-family: QuickKissPersonalUse;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #ffffff;
  width: fit-content;
  font-size: 2.5rem;

  @media screen and (min-width: 1024px) {
    font-size: 3.75rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.125rem;
  }
`;

const ContentContact = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query {
      socials: allMarkdownRemark(filter: { collection: { eq: "social" } }) {
        edges {
          node {
            file
            frontmatter {
              facebook {
                display_name
                link
              }
              instagram {
                display_name
                link
              }
              phone {
                display_name
                link
              }
              address {
                display_name
                link
              }
            }
          }
        }
      }
      books: allMarkdownRemark(filter: { collection: { eq: "common" } }) {
        edges {
          node {
            file
            frontmatter {
              book_or_events_title
              book_or_events_content
            }
          }
        }
      }
    }
  `);

  const socials = data.socials.edges.map((v) => v.node.frontmatter)[0];
  const books = data.books.edges.map((v) => v.node.frontmatter)[0];

  return (
    <PlayUrlContext.Consumer>
      {() => (
        <ContentContactWrapper className='py-12 md:py-8'>
          <ImageWrapper className='w-11/12' style={{ margin: '0 auto' }}>
            <div className='grid grid-cols-2 gap-2 md:grid-cols-1 md:px-3'>
              <ArticleWrapper className='font-serif px-10 md:px-1'>
                <Header className='text-white '>
                  {books.book_or_events_title}
                </Header>
                <ContentArticleWrapper className='pt-8 md:pt-4'>
                  {books.book_or_events_content}
                </ContentArticleWrapper>
              </ArticleWrapper>
              <ArticleWrapper className='font-serif px-10 md:pt-6 md:px-1'>
                <Header className='text-white '>
                  Contacts
                </Header>
                <ContentArticleWrapper className='pt-8 md:pt-4'>
                  <a href={socials.facebook.link} target='_blank'>
                    <TextIcon>
                      <SiFacebook className='md:w-3' size={20} />
                      <Text>{socials.facebook.display_name}</Text>
                    </TextIcon>
                  </a>
                  <a href={socials.instagram.link} target='_blank'>
                    <TextIcon>
                      <ImInstagram className='md:w-3' size={20} />
                      <Text>{socials.instagram.display_name}</Text>
                    </TextIcon>
                  </a>
                  <a
                    href={`tel:${socials.phone.display_name}`}
                    target='_blank'
                  >
                    <TextIcon>
                      <FaPhoneAlt className='md:w-3' size={20} />
                      <Text>{socials.phone.display_name}</Text>
                    </TextIcon>
                  </a>
                  <a href={socials.address.link} target='_blank'>
                    <TextIcon>
                      <FaMapMarkerAlt className='md:w-3' size={20} />
                      <Text>{socials.address.display_name}</Text>
                    </TextIcon>
                  </a>
                </ContentArticleWrapper>
              </ArticleWrapper>
            </div>
          </ImageWrapper>
        </ContentContactWrapper>
      )}
    </PlayUrlContext.Consumer>
  );
};

ContentContact.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
};

export default ContentContact;
