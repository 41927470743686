import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "~/styles/slide.css";

import { Pagination } from "swiper";
import { SiFacebook, SiTwitter } from "react-icons/si";
import { ImInstagram } from "react-icons/im";

const ContentSlideWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 570px;

  @media screen and (min-width: 1024px) {
    height: 750px;
  }

  @media screen and (max-width: 450px) {
    padding-top: 56px;
    height: 390px;
  }
`;

const ContainerWrapper = styled.div`
  height: 100%;
  position: relative;
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    height: 100%;
  }

  @media screen and (max-width: 450px) {
    height: 380px;
  }
`;

const ContainerContent = styled.div`
  position: absolute; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  width: 100%;
  height: 100%;
  text-align: left;
  padding-top: 4rem;
  padding-left: 3.5rem;
  padding-right: 1.25rem;

  @media screen and (min-width: 1024px) {
    padding-top: 3rem;
    padding-left: 6rem;
    padding-right: 1.25rem;
  }

  @media screen and (max-width: 450px) {
    padding-top: 2.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

`;


const Header = styled.div`
  font-family: QuickKissPersonalUse;
  color: #C2A466;
  font-size: 60px;

  @media screen and (min-width: 1024px) {
    font-size: 100px;
  }

  @media screen and (max-width: 450px) {
    font-size: 40px;
  }
`;

const Content = styled.div`
  padding-top: 15px;
  color: #fff;
  align-content: start;
  font-size: 18px;
  width: 80%;

  @media screen and (min-width: 1024px) {
    font-size: 20px;
    width: 60%;
  }

  @media screen and (max-width: 450px) {
    font-size: 12px;
    width: 100%;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  right: 50%;
  bottom: 8%;
  transform: translate(50%, -50%);

  .yellow-background {
    background-color: #C2A468;
  }

  @media screen and (min-width: 1024px) {
    bottom: 8%;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 450px) {
    bottom: 15%;
    font-size: 0.5rem;
  }
`;

const Box = styled.div`
  border-color: #C2A468;
  font-family: 'Barboothatmatts';
`;

const ContentSlider = () => {
  return(
    <ContentSlideWrapper className="w-full pt-24 md:pt-12">
      <Swiper pagination={true} modules={[Pagination]} className="swiper-button-prev swiper-button-white">
        <SwiperSlide>
          <ContainerWrapper className="w-full">
            <img src="/images/banner-1.jpg" alt="banner1" className="h-full"/>
            <ContainerContent>
              <Header>Bar and Restaurant</Header>
              <Content>A restaurant is a place where you can eat a meal and pay for it. In restaurants your food is usually served to you at your table by a waiter or waitress.
                <hr className="border mt-5 md:border-1 md:w-9/12 md:mt-3"></hr>
                <div className="grid grid-cols-3 gap-1 w-1/4 py-4 md:py-2">
                  <SiFacebook size={30} className="w-8 md:w-4"/>
                  <SiTwitter size={30} className="w-8 md:w-4"/>
                  <ImInstagram size={30} className="w-8 md:w-4"/>
                </div>
              </Content>
              <BoxWrapper className="flex justify-center">
                <Box className="grid grid-cols-5 gap-2 border-2 w-3/7 text-white md:border-1 md:border">
                  <div className="col-span-2 py-1 yellow-background">
                    <span>OPENING TIME</span>
                  </div>
                  <div className="col-span-3 py-1 px-6 md:px-4">
                    <span>MON - SUN : 5PM - 2AM</span>
                  </div>
                </Box>
              </BoxWrapper>
            </ContainerContent>
          </ContainerWrapper>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerWrapper className="w-full">
            <img src="/images/gellery-6.jpg" alt="image6" className="h-full"/>
            <ContainerContent>
              <Header>Restaurant</Header>
              <Content>A restaurant is a place where you can eat
                <hr className="border mt-5 md:border-1 md:w-9/12 md:mt-3"></hr>
                <div className="grid grid-cols-3 gap-1 w-1/4 py-4 md:py-2">
                  <SiFacebook size={30} className="w-8 md:w-4"/>
                  <SiTwitter size={30} className="w-8 md:w-4"/>
                  <ImInstagram size={30} className="w-8 md:w-4"/>
                </div>
              </Content>
              <BoxWrapper className="flex justify-center">
                <Box className="grid grid-cols-5 gap-2 border-2 w-3/7 text-white md:border-1 md:border">
                  <div className="col-span-2 py-1 yellow-background">
                    <span>OPENING TIME</span>
                  </div>
                  <div className="col-span-3 py-1 px-6 md:px-4">
                    <span>MON - SUN : 5PM - 2AM</span>
                  </div>
                </Box>
              </BoxWrapper>
            </ContainerContent>
          </ContainerWrapper>
        </SwiperSlide>
        <SwiperSlide>
          <ContainerWrapper className="w-full">
            <img src="/images/gellery-9.jpg" alt="image9" className="h-full"/>
            <ContainerContent className="px-24 pt-36">
              <Header>What is Cosca?</Header>
              <Content>The quick brown fox jumps over the lazy dog.</Content>
            </ContainerContent>
          </ContainerWrapper>
        </SwiperSlide>
      </Swiper>
    </ContentSlideWrapper>
  );
};

export default ContentSlider;
