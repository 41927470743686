import React from 'react';
import styled from 'styled-components';
import PlayUrlContext from '~/context/play-url';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: relative;
`;

const Content = styled.div`
  margin: 0 auto;
`;

const ContentLayout = ({ children }) => {
  return (
    <PlayUrlContext.Consumer>
      {() => (
        <Wrapper>
          <Content className='w-11/12'>
            {children}
          </Content>
        </Wrapper>
      )}
    </PlayUrlContext.Consumer>
  );
};

export default ContentLayout;
