import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import PlayUrlContext from "~/context/play-url";

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: #000;
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
`;


const InputContacts = ({ langKey }) => {
  return (
    <PlayUrlContext.Consumer>
      {() => (
        <InputWrapper className="w-9/12">
          <form className="w-full max-w-lg">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name">
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-black text-gray-700 border border-white rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="Jane">
            </input>
            <input className="appearance-none block w-full bg-black text-gray-700 border border-white rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe">
            </input>
          </form>
        </InputWrapper>
      )}
    </PlayUrlContext.Consumer>
  );
};

InputContacts.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
};

export default InputContacts;
