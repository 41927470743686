import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlayUrlContext from '~/context/play-url';

const LineHeaderWrapper = styled.div`
  width: 100;
  display: flex;
  justify-content: center;
  font-size: 2rem;

  @media screen and (min-width: 1024px) {
    font-size: 2.25rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.5rem;
  }
`;

const LineHeaderContent = styled.div`
  text-align: center;
  border-bottom: 1px solid #fff;
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-family: QuickKissPersonalUse;

  span {
    padding: 0 1.5rem;
    background: #000;
  }
`;

const LineHeader = ({ content }) => {
  return (
    <PlayUrlContext.Consumer>
      {() => (
        <LineHeaderWrapper className="pb-4 md:pb-1">
          <LineHeaderContent className="w-1/3 md:w-4/6">
            <span>{content}</span>
          </LineHeaderContent>
        </LineHeaderWrapper>
      )}
    </PlayUrlContext.Consumer>
  );
};

LineHeader.propTypes = {
  content: PropTypes.string,
};

export default LineHeader;
