import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../components/font-awsome';
import { graphql } from 'gatsby';

import Layout from '~/components/layout';
import Seo from '~/components/seo';
// import Slider from "~/components/slider"
import ContentSlider from '~/components/content-swiper';
import ContentUpdate from '~/components/content-update';
import ContentEvent from '~/components/content-event';
import ContentGellery from '~/components/content-gellery';
import ContentContact from '~/components/content-contact';
import ContentMap from '~/components/content-map';
import Footer from '~/components/footer';
import * as Scroll from 'react-scroll';

let Element = Scroll.Element;

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
    markdownRemark: {
      fields: { langKey },
      frontmatter,
    },
  },
  pageContext,
}) => {
  const bannerList = edges
    .filter((item) => item.node.file.split('/')[1] === langKey)
    .map((item) => item.node);

  return (
    <Layout pageContext={pageContext}>
      <Seo
        title={frontmatter.metadata.metaTitle}
        description={frontmatter.metadata.metaDescription}
      />
      <Element name='ContentSlider'>
        <ContentSlider></ContentSlider>
      </Element>
      <Element name='ContentUpdate'>
        <ContentUpdate langKey={pageContext.langKey} />
      </Element>
      <Element name='ContentEvent'>
        <ContentEvent langKey={pageContext.langKey} />
      </Element>
      <ContentGellery />
      <Element name='ContentContact'>
        <ContentContact />
      </Element>
      <ContentMap />
      <Element name='Footer'>
        <Footer />
      </Element>
    </Layout>
  );
};

export const query = graphql`
  query IndexPageTemplate($langKey: String!) {
    allMarkdownRemark(
      filter: { collection: { eq: "banner" } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          file
          frontmatter {
            image
            dot_thumbnail
            banner_text
            button_text
          }
        }
      }
    }

    markdownRemark(
      frontmatter: { templateKey: { eq: "index" } }
      fields: { langKey: { eq: $langKey } }
    ) {
      id
      frontmatter {
        metadata {
          metaDescription
          metaTitle
        }
        filter_toggle
        filter_show
        show_more
        filters
        sort
        signin
        login
        cancel
        username
        password
        search_label
        search_button
      }
      fields {
        langKey
      }
    }
  }
`;

export default IndexPage;
