import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import PlayUrlContext from '~/context/play-url';
import LineHeader from '~/components/line-header';
import ContentLayout from '~/components/content-layout';
import event_image from '~/images/event_image.png';
import { Link, graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';

const EventWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 30px;
`;

const ContentTable = styled.div`
  border: 2px solid #383838;
  text-align: center;
`;

const EventImageTable = styled.div`
  border-color: #383838;
  margin: auto;
  height: 50vw;
  position: relative;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 50vw;
    height: auto;
    margin: auto;
    width: auto;
  }
`;

const TableCSS = css`
  border-color: #383838;
`;

const TableHead = styled.th`
  padding: 0.5rem 0;
  font-size: 1rem;
  position: relative;

  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.6rem;
  }

  &:before {
    content: '${(props) => props.content}';
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 2px;
    font-size: 0.4rem;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media screen and (min-width: 1024px) {
      font-size: 0.7rem;
      left: 5px;
    }

    @media screen and (max-width: 450px) {
      font-size: 0.4rem;
      left: 1px;
    }
  }

  .yellow-text {
    color: #d4b370;
  }
`;

const DesktopImageWrapper = css`
  max-width: 30%;
  position: relative;
`;

const DesktopImageCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  margin: auto;
  width: auto;
  max-height: -webkit-fill-available;
`;

const responsiveTable = css`
  min-height: 50cw;

  @media screen and (min-width: 1024px) {
    min-height: 300px;
  }
`;

const Event = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { collection: { eq: "event" } }) {
        edges {
          node {
            file
            frontmatter {
              image
              date_time
              start_time
              end_time
              topic_event
              content_event
            }
          }
        }
      }
    }
  `);

  const events = data.allMarkdownRemark.edges.length
    ? data.allMarkdownRemark.edges.map((v) => v.node.frontmatter)
    : {};

  const convertToDateTime = (value, type) => {
    if (type === 'YEAR_MONTH') return moment(value).format('YYYY MMM');
    return `${moment(value).format('Do')} ${moment(value)
      .format('A')
      .split('')
      .join('.')}.`;
  };

  const convertToTimeMeridiem = (value) => {
    return `${moment(value).format('HH:mm')} ${moment(value)
      .format('A')
      .split('')
      .join('.')}.`;
  };

  return (
    <PlayUrlContext.Consumer>
      {() => (
        <ContentLayout>
          <EventWrapper className='py-12 md:py-8'>
            <LineHeader content={'Events'} />
            <div className='grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-2'>
              {events.map((item, idx) => (
                <ContentTable className='flex' key={idx}>
                  <div
                    css={DesktopImageWrapper}
                    className='w-96 h-full w-full basis-96 md:hidden m-auto'
                  >
                    <img src={`/${item.image}`} css={DesktopImageCss} />
                  </div>
                  <div
                    className='w-full flex justify-between flex-wrap'
                    style={{ height: 'max-content' }}
                  >
                    <EventImageTable className='w-full lg:hidden md:flex md:border-b-2'>
                      <img src={`/${item.image}`} />
                    </EventImageTable>
                    <table className='table-auto w-full' css={responsiveTable}>
                      <thead>
                        <tr>
                          <TableHead
                            css={TableCSS}
                            className='w-1/3 border-l-2 md:border-l-0'
                          >
                            {convertToDateTime(item.date_time, 'YEAR_MONTH')}{' '}
                            <span className='yellow-text'>
                              {convertToDateTime(item.date_time)}
                            </span>
                          </TableHead>
                          <TableHead
                            content='start'
                            css={TableCSS}
                            className='w-1/3 border-l-2'
                          >
                            <span className='yellow-text'>
                              {convertToTimeMeridiem(item.start_time)}
                            </span>
                          </TableHead>
                          <TableHead
                            content='finish'
                            css={TableCSS}
                            className='w-1/3 border-l-2'
                          >
                            <span className='yellow-text'>
                              {convertToTimeMeridiem(item.end_time)}
                            </span>
                          </TableHead>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          css={TableCSS}
                          className='border-t-2 border-l-2 md:border-l-0'
                        >
                          <td colSpan={3} className='text-left p-6 align-top'>
                            <h2>{item.topic_event}</h2>
                            <div>
                              {item.content_event.map((content, index) => (
                                <div key={index}>{content}</div>
                              ))}
                              {/* The quick brown fox jumps over the lazy dog. */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ContentTable>
              ))}
            </div>
          </EventWrapper>
        </ContentLayout>
      )}
    </PlayUrlContext.Consumer>
  );
};

Event.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
};

export default Event;
