import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PlayUrlContext from '~/context/play-url';
import { Link, graphql, useStaticQuery } from 'gatsby';

const ContentMapWrapper = styled.div`
  height: auto;
  position: relative;
  background-image: url('/images/map.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (min-width: 1024px) {
    background-size: cover;
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 450px) {
    background-size: cover;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  background-color: #000;
  width: 50%;

  @media screen and (min-width: 1024px) {
    width: 25%;
  }

  @media screen and (max-width: 450px) {
    width: 50%;
  }
`;

const TextBoxAddress = styled.div`
  font-family: system-ui;
  font-size: 10px;
  padding: 0.75rem;

  @media screen and (min-width: 1024px) {
    font-size: 12px;
    padding: 1rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 6px;
    padding: 0.5rem;
  }
`;

const BoxGetDirection = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: system-ui;
  font-size: 12px;

  @media screen and (min-width: 1024px) {
    font-size: 14px;
  }

  @media screen and (max-width: 450px) {
    font-size: 8px;
  }
`;

const ContentMap = ({ langKey }) => {
  const data = useStaticQuery(graphql`
    query {
      socials: allMarkdownRemark(filter: { collection: { eq: "social" } }) {
        edges {
          node {
            file
            frontmatter {
              address {
                display_name
                link
              }
            }
          }
        }
      }
    }
  `);

  const socials = data.socials.edges.map((v) => v.node.frontmatter)[0];

  return (
    <PlayUrlContext.Consumer>
      {() => (
        <ContentMapWrapper className='w-full'>
          <ImageWrapper
            style={{ margin: '0 auto' }}
          >
            <TextBoxAddress>
              <div className='grid grid-cols-2 gap-4 md:gap-2'>
                <div>
                  <p className='text-white font-bold'>Cosca Bar</p>
                  <p className='text-slate-300'>
                    {socials.address.display_name}
                  </p>
                </div>
                  <BoxGetDirection className='place-self-center border-2 text-white'>
                    <a href={socials.address.link} target='_blank'>Get Direction {'>>'}</a>
                  </BoxGetDirection>
              </div>
            </TextBoxAddress>
          </ImageWrapper>
        </ContentMapWrapper>
      )}
    </PlayUrlContext.Consumer>
  );
};

ContentMap.propTypes = {
  bannerList: PropTypes.array,
  playUrl: PropTypes.string,
};

export default ContentMap;
